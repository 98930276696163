@font-face {
    font-family: 'Pathfinder2eActions';
    src: url('Pathfinder2eActions.woff2') format('woff2'),
        url('Pathfinder2eActions.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.pf2.pf2-action-0::before {
  font-family: 'Pathfinder2eActions';
  content: '\0034';
  font-size: x-large;
}

.pf2.pf2-action-1::before {
  font-family: 'Pathfinder2eActions';
  content: '\0031';
  font-size: x-large;
}

.pf2.pf2-action-2::before {
  font-family: 'Pathfinder2eActions';
  content: '\0032';
  font-size: x-large;
}

.pf2.pf2-action-3::before {
  font-family: 'Pathfinder2eActions';
  content: '\0033';
  font-size: x-large;
}
.pf2.pf2-action-r::before {
  font-family: 'Pathfinder2eActions';
  content: '\0035';
  font-size: x-large;
}
